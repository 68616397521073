import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import { Wrapper } from './styles';

export const SectionLoader = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const height = 234;
	return (
		<Wrapper maxWidth='xl'>
			<Grid container spacing={0} alignContent='center' justifyContent='center' paddingTop={3} paddingBottom={1}>
				<Grid item xs={12}>
					<Skeleton variant='rounded' height={25} />
				</Grid>
				<Grid container spacing={3} alignContent='center' justifyContent='center' paddingTop={3}>
					<Grid item xs={9} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
					{!isMobile && (
						<Grid item xs={9} sm={4} md={3} lg={2}>
							<Skeleton variant='rounded' height={height} />
						</Grid>
					)}
					{!isMobile && (
						<Grid item xs={9} sm={4} md={3} lg={2}>
							<Skeleton variant='rounded' height={height} />
						</Grid>
					)}
					{!isMobile && (
						<Grid item xs={9} sm={4} md={3} lg={2}>
							<Skeleton variant='rounded' height={height} />
						</Grid>
					)}
					{!isTablet && (
						<Grid item xs={9} sm={4} md={3} lg={2}>
							<Skeleton variant='rounded' height={height} />
						</Grid>
					)}
					{!isTablet && (
						<Grid item xs={9} sm={4} md={3} lg={2}>
							<Skeleton variant='rounded' height={height} />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Wrapper>
	);
};
