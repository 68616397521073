import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroller';
import { ContentSection } from '@presentation/components/ContentSection';
import { CardsCarousel } from '@presentation/components/CardsCarusel';
import { ArtworkBanner } from '@presentation/components/ArtworkBanner';
import type { HomepageService } from '@domain/usecases/homepage/HomepageService';
import type { ContentItemWithAuthorModel } from '@domain/models';
import { useShowContentDetailsModalBasedOnUrl } from '@presentation/pages/Home/hooks/useShowContentDetailsModalBasedOnUrl';
import { useGetHomePageSection } from '@presentation/pages/Home/hooks/useGetHomePageSection';
import { SectionLoader } from '@presentation/components/Loader';
import { Outlet } from 'react-router-dom';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import Facebook from '@presentation/components/Facebook/Facebook';

const HomePage = ({ homepageService }: { homepageService: HomepageService }) => {
	const { generateLink } = useSmartBanner();
	const [dailyPuzzle, setDailyPuzzle] = useState<ContentItemWithAuthorModel | null>();
	const [isLoading, setIsLoading] = useState(true);

	const { sections, hasMore, fetchContent } = useGetHomePageSection(homepageService);

	useEffect(() => {
		if (window.location.pathname === '/') {
			generateLink('HOME');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateLink, window.location.pathname]);

	/**
	 * Handles showing modal /puzzle/:id is visited directly, show a modal on this page.
	 */
	useShowContentDetailsModalBasedOnUrl();

	useEffect(() => {
		setIsLoading(true);

		homepageService.getDailyPuzzleForToday().then((dailyPuzzle) => {
			setDailyPuzzle(dailyPuzzle);

			setIsLoading(false);
		});
	}, [homepageService]);

	return (
		<>
			<Facebook />
			<ArtworkBanner
				isLoading={isLoading}
				id={dailyPuzzle?.id || ''}
				title={dailyPuzzle?.name || ''}
				description={format(new Date(), 'do MMMM yyyy')}
				imageSrc={dailyPuzzle?.imageSrc || ''}
				backgroundImageSrc={dailyPuzzle?.imageSrcLowQuality || ''}
				creator={dailyPuzzle?.author || null}
			/>
			<InfiniteScroll
				pageStart={0}
				initialLoad
				loadMore={() => fetchContent()}
				hasMore={hasMore}
				loader={<SectionLoader key='loader' />}
			>
				{sections.map((section) => (
					<ContentSection
						key={section.id}
						title={section.title}
						exploreAllLink={!section.isDynamic ? `/category/${section.id}` : undefined}
					>
						<CardsCarousel items={section.items} />
					</ContentSection>
				))}
			</InfiniteScroll>
			<Outlet />
		</>
	);
};

export default HomePage;
