import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { type HomepageSectionContent, HomepageService } from '@domain/usecases/homepage';

const HOMEPAGE_SECTIONS_LIMIT = 4;
export const useGetHomePageSection = (homepageService: HomepageService) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);
	const [sections, setSections] = useState<HomepageSectionContent[]>([]);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const homepageCollections = useMemo(() => homepageService.getHomepageCollections(), [homepageService]);

	// Handlers
	const fetchContent = useCallback(async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);

		const offset = HOMEPAGE_SECTIONS_LIMIT * page;

		try {
			const res = await homepageService.getNextHomepageSectionsData(
				homepageCollections.slice(offset, offset + HOMEPAGE_SECTIONS_LIMIT)
			);

			setSections((prevState) => {
				return [...prevState, ...res];
			});
			setHasMore(res.length >= HOMEPAGE_SECTIONS_LIMIT);
			setPage((prevState) => prevState + 1);
		} catch (e) {
			enqueueSnackbar(t('screens.home.errors.fetchDataError'), { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	}, [isLoading, enqueueSnackbar, t, homepageService, page, homepageCollections]);

	return {
		sections,
		hasMore,
		fetchContent,
		isLoading,
	};
};
