import { useCallback, useMemo } from 'react';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import { isMobile, isTablet } from 'react-device-detect';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';
import { useDownloadApp } from '@presentation/hooks/useDownloadApp';

export function useSingularLinkRedirect(
	type: keyof typeof LinkGeneratorService.LINK_TYPES,
	id?: string
): { redirectToApp: (event: any) => void } {
	const { setSmartLink } = useSmartBanner();
	const { openDownloadModal } = useDownloadApp();

	const singularLink = useMemo(() => LinkGeneratorService.getSingularLink(type, id), [type, id]);

	const redirectToApp = useCallback(
		(event: any) => {
			event.stopPropagation();

			setSmartLink(singularLink);

			if (isMobile || isTablet) {
				(window as any).location = singularLink;
			} else {
				openDownloadModal();
			}
		},
		[singularLink, setSmartLink, openDownloadModal]
	);

	return {
		redirectToApp,
	};
}
