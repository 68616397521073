import { firestore } from '@infrastructure/firebase';
import type { CreatorModel } from '@domain/models';
import {
	collection,
	query,
	where,
	orderBy,
	limit,
	getDocs,
	startAfter,
	getCountFromServer,
	doc,
	getDoc,
} from 'firebase/firestore';
import type { ICreatorsRepository } from '@domain/usecases/creators/CreatorsRepository';
import type { AuthorDto } from '@infrastructure/firebase/firestore/dto/AuthorDto';
import { getFirstDocument } from '@infrastructure/firebase/utilities';
import type { CreatorType } from '@infrastructure/firebase/models';
import type { ListOrder } from '@domain/types/ListOrder';
import { CreatorSortField } from '@domain/usecases/creators/CreatorSortField';

export class FirestoreCreatorRepository implements ICreatorsRepository {
	private readonly collectionRef;

	constructor() {
		this.collectionRef = collection(firestore, `/usersWeb`);
	}

	async count(): Promise<number> {
		const queryRef = query(
			this.collectionRef,
			where('status', '==', 'approved'),
			where('Role', '==', 'artist'),
			where('Puzzles', '>', 0)
		);

		const ref = await getCountFromServer(queryRef);

		return ref.data().count;
	}

	async getCreatorByProfileLink(profileLink: string): Promise<CreatorModel | null> {
		const document = await getFirstDocument<CreatorType>('usersWeb', [
			{ attribute: 'profileLink', value: profileLink, operator: '==' },
		]);

		if (!document) {
			return null;
		}

		return this.mapToCreatorModel(document.id, document.data() as AuthorDto);
	}

	async getCreatorById(id: string): Promise<CreatorModel | null> {
		const document = await getDoc(doc(firestore, `/usersWeb`, id));

		if (!document.exists()) {
			return null;
		}

		return this.mapToCreatorModel(document.id, document.data() as AuthorDto);
	}

	private mapToCreatorModel(id: string, dbo: AuthorDto): CreatorModel {
		return {
			id: id,
			name: dbo.username,
			avatarSrc: dbo.profileImageUrl,
			profileLink: dbo.profileLink,
			coverImageUrl: dbo.coverImageUrl,
			puzzlesCount: dbo.Puzzles,
			followersCount: dbo.Followers,
			followingCount: dbo.Following,
			status: dbo.status,
			country: dbo.Country,
			createdAt: dbo?.createdAt ? dbo?.createdAt.toDate() : null,
		};
	}

	async getCreators(options: {
		limit: number;
		order?: ListOrder<CreatorSortField>;
		last?: string;
	}): Promise<CreatorModel[]> {
		let lastRef;
		if (options.last) {
			lastRef = await getDoc(doc(firestore, `/usersWeb`, options.last));
		}

		const queryRef = query(
			this.collectionRef,
			where('status', '==', 'approved'),
			where('Role', '==', 'artist'),
			where('Puzzles', '>', 0),
			orderBy(options.order?.field ?? CreatorSortField.name, options.order?.direction ?? 'asc'),
			...(lastRef ? [startAfter(lastRef)] : []),
			limit(options.limit)
		);

		const querySnapshot = await getDocs(queryRef);
		return Promise.all(
			querySnapshot.docs.map(async (document: any) => {
				const data = document.data() as AuthorDto;

				return this.mapToCreatorModel(document.id, data);
			})
		);
	}
}
