import React from 'react';
import type { FC } from 'react';
import { Header } from '@presentation/components/Header';
import { Footer } from '@presentation/components/Footer';
import { ContentDetailsModalContextProvider } from '@presentation/context/ContentDetailsModalContextProvider';
import { ModalContextProvider } from '@presentation/context/ModalContextProvider';
import { ScrollRestoration } from 'react-router-dom';

type ListLayoutProps = {
	children: React.ReactElement;
};
const ListLayout: FC<ListLayoutProps> = ({ children }) => {
	return (
		<ModalContextProvider>
			<ContentDetailsModalContextProvider>
				<Header />
				{children}
				<Footer />
				<ScrollRestoration />
			</ContentDetailsModalContextProvider>
		</ModalContextProvider>
	);
};

export default ListLayout;
