import {
	createContext,
	type FunctionComponent,
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

type ModalContext = {
	/* Open specific modal*/
	openModal: <Props>(id: string, modalComponent: FunctionComponent<Props>, props: Props) => void;
	closeModal: (id: string) => void;
	updateModal: <Props>(id: string, props: Props) => void;
};

type Modal = {
	/** Unique modal identifier */
	id: string;
	/** Modal component */
	Component: FunctionComponent<any>;
	/** Modal props */
	props: any;
};

const initialValue: ModalContext = {
	openModal: () => {},
	closeModal: () => {},
	updateModal: () => {},
};

export const ModalContext = createContext(initialValue);

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
	/**
	 * Stack structure where last one is currently visible and focused.
	 */
	const [modals, setModals] = useState<Array<Modal>>([]);

	/**
	 * Creates a new modal passing props to it.
	 */
	const openModal = useCallback<ModalContext['openModal']>((id, Component, props) => {
		setModals((prevModals) => {
			if (prevModals.some((modal) => modal.id === id)) {
				return prevModals.map((modal) => (modal.id === id ? { id, Component, props } : modal));
			}

			return [...prevModals, { id, Component, props }];
		});
	}, []);

	/**
	 * Close modal with given id (removes modal).
	 */
	const closeModal = useCallback<ModalContext['closeModal']>((id) => {
		setModals((prevModals) => {
			return prevModals.map((modal) => {
				if (modal.id === id) {
					// Just set open to `false` while keeping all other props the same.
					return {
						...modal,
						props: {
							...modal.props,
							open: false,
						},
					};
				}

				return modal;
			});
		});
	}, []);

	useEffect(() => {
		const closeAllModals = () => {
			modals.forEach((modal) => {
				closeModal(modal.id);
			});
		};

		window.addEventListener('popstate', closeAllModals);

		return () => {
			window.removeEventListener('popstate', closeAllModals);
		};
	}, [modals, closeModal]);

	/**
	 * Update props for modal with given id.
	 */
	const updateModal = useCallback<ModalContext['updateModal']>((id, props) => {
		setModals((prevModals) => {
			return prevModals.map((modal) => {
				if (modal.id === id) {
					return {
						...modal,
						props,
					};
				}

				return modal;
			});
		});
	}, []);

	return (
		<ModalContext.Provider value={{ openModal, closeModal, updateModal }}>
			{children}

			{modals.map((modal) => (
				<modal.Component key={modal.id} {...modal.props} />
			))}
		</ModalContext.Provider>
	);
};

export const useModal = () => {
	const context = useContext(ModalContext);

	if (!context) {
		throw new Error('Cannot use ModalContext outside provider');
	}

	return context;
};
