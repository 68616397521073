import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { CollectionModel } from '@domain/models/CollectionModel';
import { CollectionsService } from '@domain/usecases/collections/CollectionsService';

export const useGetCategory = (collectionsService: CollectionsService) => {
	const { contentIdWithNiceName } = useParams();

	const id = useMemo(() => contentIdWithNiceName?.split('-')[0] || '', [contentIdWithNiceName]);

	const [category, setCategory] = useState<CollectionModel | null>();
	const [isLoading, setLoading] = useState<boolean>(false);

	const fetchCategory = useCallback(async () => {
		setLoading(true);

		const document = await collectionsService.getCollectionById(id);

		setCategory(document);

		setLoading(false);
	}, [id, collectionsService]);

	useEffect(() => {
		fetchCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return { category, isLoading, id };
};
