import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import './config/sentry';
import './config/firebase';
import App from './main/App.jsx';
import './index.css';

ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
