import { ContentService } from '@domain/usecases/content/ContentService';
import { makePuzzleRepository } from '@main/factories/makePuzzleRepository';
import { makeCollectionRepository } from '@main/factories/makeCollectionRepository';

export const makeContentService = (): ContentService => {
	const puzzleRepository = makePuzzleRepository();
	const collectionRepository = makeCollectionRepository();

	return new ContentService(puzzleRepository, collectionRepository);
};
