import { type DocumentSnapshot } from 'firebase/firestore';

import type { FilterItem } from './type/FilterItem';
import type { OrderItem } from './type/OrderItem';
import { getDocuments } from './getDocuments';

export const getFirstDocument = async <Schema>(
	collectionName: string,
	filters?: FilterItem<Schema>[],
	ordering?: OrderItem<Schema>[],
	last?: DocumentSnapshot
) => {
	const snapshot = await getDocuments<Schema>(collectionName, filters, ordering, last, 1);
	return snapshot.docs?.[0];
};
