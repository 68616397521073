import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import type { OrderByDirection } from '@domain/enum/OrderByDirection';

import { SortContainer } from './styles';

export type SortOption = {
	label: string;
	value: string;
};

export type ListHeaderProps = {
	sortOptions: SortOption[];
	sortBy?: string;
	onSortChange: (orderBy: string, orderDirection: OrderByDirection) => void;
};
export const ListHeader = (props: ListHeaderProps) => {
	const { sortOptions, sortBy, onSortChange } = props;

	return (
		<SortContainer container justifyContent='flex-end' alignItems='center'>
			<Grid item xs={12} sm={4} md={3} lg={2}>
				<FormControl fullWidth={true}>
					<InputLabel id='content-sort'>Sort</InputLabel>
					<Select
						fullWidth={false}
						labelId='content-sort'
						size='small'
						value={sortBy}
						MenuProps={{
							disableScrollLock: true,
						}}
						label='Sort'
						onChange={(event) => {
							const value = event.target.value;
							const [orderBy, orderDirection] = value.split('|') as [string, OrderByDirection];

							onSortChange(orderBy, orderDirection);
						}}
					>
						{sortOptions.map((option) => (
							<MenuItem value={option.value} key={option.value}>
								<Typography fontSize={16} key={option.label}>
									<Trans i18nKey={option.label} />
								</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</SortContainer>
	);
};
