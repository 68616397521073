import type { ListOrder } from '@domain/types/ListOrder';
import type { OrderByDirection } from '@domain/enum/OrderByDirection';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export function useSort<T extends string>(
	defaultField: T,
	defaultDir: OrderByDirection = 'asc'
): { sort: ListOrder<T>; handleSort: (field: T, order: OrderByDirection) => void } {
	const [searchParams, setSearchParams] = useSearchParams();
	const sort: ListOrder<T> = useMemo(() => {
		return {
			field: (searchParams.get('orderBy') ?? defaultField) as T,
			direction: (searchParams.get('orderDir') ?? defaultDir) as OrderByDirection,
		};
	}, [searchParams, defaultField, defaultDir]);

	const handleSort = useCallback(
		(orderBy: T, orderDir: OrderByDirection) => {
			setSearchParams({ orderBy, orderDir });
		},
		[setSearchParams]
	);

	return {
		sort,
		handleSort,
	};
}
