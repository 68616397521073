import React, { createContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { RemoteConfigRepository } from '@infrastructure/firebase/remoteConfig/RemoteConfigRepository';

const RemoteConfigContext = createContext<undefined>(undefined);

interface RemoteConfigProviderProps {
	children: React.ReactNode;
}

export const RemoteConfigProvider = ({ children }: RemoteConfigProviderProps) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		RemoteConfigRepository.init().then(() => {
			setIsLoading(false);
		});
	}, []);

	if (isLoading) {
		return (
			<Container
				maxWidth='xl'
				sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<CircularProgress />
			</Container>
		);
	}

	return <RemoteConfigContext.Provider value={undefined}>{children}</RemoteConfigContext.Provider>;
};
