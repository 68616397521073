import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Trans } from 'react-i18next';

import { ButtonContainer, Wrapper, Container } from './styles';

export type ContentSectionProps = {
	title: string;
	exploreAllLink?: string;
	children: React.ReactElement;
};
export const ContentSection = (props: ContentSectionProps) => {
	const { title, exploreAllLink, children } = props;

	return (
		<Wrapper>
			<Container maxWidth='xl'>
				<Grid container display='flex' alignItems='center' justifyContent='space-between' marginBottom={1}>
					<Grid item xs={8} md={6}>
						<Typography variant='h2'>{title}</Typography>
					</Grid>
					{exploreAllLink && (
						<ButtonContainer item xs={3} md={6} display='flex' alignItems='center'>
							<Link href={exploreAllLink} style={{ display: 'block' }}>
								<Typography variant='link'>
									<Trans i18nKey='components.contentSection.exploreAll' />
								</Typography>
							</Link>
						</ButtonContainer>
					)}
				</Grid>
			</Container>
			<Box display='flex'>{children}</Box>
		</Wrapper>
	);
};
