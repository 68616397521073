import { useCallback, useEffect, useState, useRef } from 'react';
import type { CollectionsService } from '@domain/usecases/collections/CollectionsService';
import type { CollectionModel } from '@domain/models/CollectionModel';
import { CollectionSortField } from '@domain/usecases/collections/CollectionSortField';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSort } from '@presentation/hooks/useSort';

export const useHomePageCategories = (collectionsService: CollectionsService) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const { sort, handleSort } = useSort<CollectionSortField>(CollectionSortField.name);

	// Data
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<CollectionModel[]>([]);
	const [count, setCount] = useState<number>(0);
	const last = useRef<string | undefined>();
	const [hasMore, setHasMore] = useState(false);

	// Handlers
	const fetchCategories = useCallback(
		async (hardReset?: boolean) => {
			if (isLoading) {
				return;
			}

			setIsLoading(true);
			const lastId = !hardReset ? last.current : undefined;

			try {
				const res = await collectionsService.getNextCollectionsPage({
					order: sort,
					last: lastId,
				});

				setData((prevState) => {
					if (hardReset) {
						return [...res.items];
					}

					return [...prevState].concat([...res.items]);
				});
				setCount(res.count);

				if (res.items.length) {
					last.current = res.items[res.items.length - 1].id;
				}
				setHasMore(res.hasMore);
			} catch (e) {
				enqueueSnackbar(t('screens.categories.errors.fetchDataError'), { variant: 'error' });
			} finally {
				setIsLoading(false);
			}
		},
		[sort, isLoading, collectionsService, enqueueSnackbar, t]
	);

	// Hooks
	useEffect(() => {
		// Fetch on init
		fetchCategories(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	return {
		data,
		hasMore,
		handleSort,
		sort,
		fetchCategories,
		isLoading,
		count,
	};
};
