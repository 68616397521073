import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

export const Content = styled(DialogContent)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxWidth: '400px',
	padding: theme.spacing(2),
}));

export const LogoBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#F7F7F7',
	borderRadius: '15px',
	padding: theme.spacing(3),
	width: '100%',
	marginBottom: theme.spacing(1),
}));

export const Qr = styled('div')(({ theme }) => ({
	maxWidth: '210px',
	margin: theme.spacing(4),
}));

export const DownloadButton = styled('img')(() => ({
	display: 'block',
	maxHeight: '44px',
	maxWidth: '148px',
}));
