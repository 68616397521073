import React from 'react';
import QRCode from 'react-qr-code';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Content, DownloadButton, LogoBox, Qr } from '@presentation/modals/DownloadApp/styles';
import logo from '@presentation/assets/logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import downloadGoogle from '@presentation/assets/download-google.png';
import downloadApple from '@presentation/assets/download-apple.png';
import { LINK_APPLE_STORE, LINK_PLAY_STORE } from '@main/config/links';
import { useNavigate } from 'react-router-dom';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

const DownloadApp = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
	const navigate = useNavigate();
	const { link } = useSmartBanner();

	const { t } = useTranslation();

	return (
		<Dialog open={open} onClose={onClose ? onClose : () => navigate('/')}>
			<Content>
				<LogoBox display='flex' justifyContent='center'>
					<img src={logo} alt={t('general.header.logoAlt')} />
				</LogoBox>

				<Typography variant='subtitle2' color='#111111DE' textAlign='center'>
					<Trans i18nKey='modals.downloadApp.scan' />
				</Typography>

				<Qr>
					<QRCode
						size={210}
						style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
						value={link}
						viewBox={`0 0 210 210`}
					/>
				</Qr>

				<Box display='flex' justifyContent='space-between' width='90%'>
					<Link href={LINK_PLAY_STORE} target='_blank'>
						<DownloadButton src={downloadGoogle} alt={t('general.downloadPlayStore')} />
					</Link>
					<Link href={LINK_APPLE_STORE} target='_blank'>
						<DownloadButton src={downloadApple} alt={t('general.downloadAppStore')} />
					</Link>
				</Box>
			</Content>
		</Dialog>
	);
};

export default DownloadApp;
