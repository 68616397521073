import React from 'react';
import { Button, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Card } from '@presentation/pages/Categories/components';
import IconButton from '@mui/material/IconButton';

export const Wrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.colors.background,
	borderRadius: 16,
	display: 'block',
	width: '100%',
	maxHeight: '100%',
	height: '100%',
	position: 'absolute',
	top: '50%',
	left: '50%',
	maxWidth: theme.sizes.contentModal.maxWidth,
	overflow: 'hidden',
	transform: 'translate(-50%, -50%)',
	border: 0,

	[theme.breakpoints.down(theme.sizes.contentModal.maxWidth)]: {
		borderRadius: 0,
	},

	[theme.breakpoints.up(theme.sizes.contentModal.maxWidth)]: {
		height: 'auto',
	},

	[theme.breakpoints.up('md')]: {
		height: 'unset',
		display: 'flex',
		maxWidth: 900,
	},
	[theme.breakpoints.up('lg')]: {
		display: 'flex',
		maxWidth: 1000,
	},
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	aspectRatio: '1/1',

	[theme.breakpoints.up('md')]: {
		width: '60%',
	},
}));

export const Video = styled('video')(({ theme }) => ({
	position: 'absolute',
	top: '0',
	width: '100%',
	height: '100%',
	objectFit: 'fill',
	overflow: 'hidden',

	[theme.breakpoints.down('md')]: {
		position: 'static',
	},
}));

export const Image = styled('img')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	objectFit: 'fill',
	marginLeft: 'auto',
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.down('md')]: {
		minHeight: '50vh',
		position: 'static',
	},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',

	[theme.breakpoints.up('md')]: {
		width: '40%',
	},
}));

export const KeywordsContainer = styled(Box)(() => ({
	display: 'flex',
	overflow: 'auto',
	position: 'relative',

	'&::-webkit-scrollbar': {
		display: 'none',
	},
}));

export const KeywordsGradientRight = styled(Box)(() => ({
	width: '70px',
	height: '100%',
	position: 'absolute',
	right: '0',
	top: 0,
	background: 'linear-gradient(270deg, #FFFFFF -2.99%, rgba(255, 255, 255, 0) 100%)',
}));

export const Collection = styled(Card)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		aspectRatio: 'auto',
		minHeight: '160px',
	},
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	zIndex: theme.zIndex.tooltip,
	top: 20,
	right: 20,
	backgroundColor: 'rgba(0, 0, 0, 0.56)',
	color: theme.colors.background,

	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
	},

	[theme.breakpoints.up('md')]: {
		color: theme.colors.text,
		backgroundColor: 'unset',

		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
	},
}));

export const AILabel = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 30,
	left: 30,
	width: 30,
	height: 30,
	lineHeight: '30px',
	color: theme.colors.black,
	backgroundColor: theme.colors.background,
	textAlign: 'center',
	fontSize: '13px',
	borderRadius: '5px',
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		top: 30,
		right: 30,
		left: 'unset',
		bottom: 'unset',
	},
}));

export const AIContent = styled(Box)(() => ({
	fontSize: 11,
	verticalAlign: 'center',
	lineHeight: '24px',
}));

export const AiTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '220px',
		backgroundColor: theme.colors.background,
		color: theme.colors.text,
		fontWeight: 400,
		fontSize: 11,

		[theme.breakpoints.up('md')]: {
			maxWidth: '400px',
		},
	},
}));

export const PlayInAppButton = styled(Button)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		borderRadius: 0,
	},

	[theme.breakpoints.up('md')]: {
		borderRadius: 5,
	},
}));

export const MainContentContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		overflowX: 'auto',
		maxHeight: `calc(100dvh - ${theme.sizes.contentModal.maxWidth}px - ${theme.sizes.contentModal.footerHeight}px)`,
		marginBottom: theme.sizes.contentModal.footerHeight,
	},
	[theme.breakpoints.down(theme.sizes.contentModal.maxWidth)]: {
		overflowX: 'auto',
		maxHeight: `calc(100dvh - 100dvw - ${theme.sizes.contentModal.footerHeight}px)`,
	},
}));

export const MetadataContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
		marginBottom: 0,
	},

	[theme.breakpoints.up('md')]: {
		padding: `0 ${theme.spacing(3)}`,
		marginBottom: theme.spacing(2),
	},
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		position: 'fixed',
		bottom: 0,
		width: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.95)',
	},

	[theme.breakpoints.up('md')]: {
		position: 'static',
	},
}));
