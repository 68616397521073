import { HomepageService } from '@domain/usecases/homepage/HomepageService';

import { makeConfigRepository } from './makeConfigRepository';
import { makePuzzleRepository } from './makePuzzleRepository';

export const makeHomepageService = (): HomepageService => {
	const configRepository = makeConfigRepository();
	const puzzleRepository = makePuzzleRepository();

	return new HomepageService(configRepository, puzzleRepository);
};
