import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import makeCategories from '@main/factories/pages/makeCategories';
import { ROUTES } from '@main/routes/routes';
import DefaultLayout from '@presentation/layouts/DefaultLayout';
import NotFound from '@presentation/pages/NotFound';
import SearchPage from '@presentation/pages/Search';
import makeCreator from '@main/factories/pages/makeCreator';
import makeCategory from '@main/factories/pages/makeCategory';
import DownloadApp from '@presentation/modals/DownloadApp';

import makeHomepage from '../factories/pages/makeHomepage';
import makeCreators from '../factories/pages/makeCreators';

export default createBrowserRouter([
	// HOMEPAGE
	{
		path: ROUTES.HOME,
		element: makeHomepage(),
		children: [
			{
				path: `${ROUTES.PUZZLE}/:contentIdWithNiceName`,
				element: null, // Homepage handles this by showing a modal.
			},
			{
				path: `${ROUTES.PUZZLE}/:contentId/:niceName`,
				element: null, // Homepage handles this by showing a modal.
			},
			{
				path: `${ROUTES.DOWNLOAD}`,
				element: <DownloadApp open />,
			},
		],
		errorElement: (
			<DefaultLayout>
				<NotFound />
			</DefaultLayout>
		),
	},
	// CREATORS
	{
		path: ROUTES.CREATORS,
		element: makeCreators(),
	},
	// SINGLE CREATOR
	{
		path: `${ROUTES.CREATOR}/:idOrProfileLink`,
		element: makeCreator(),
	},
	{
		path: `${ROUTES.HOME}:idOrProfileLink`,
		element: makeCreator(),
	},
	// CATEGORIES
	{
		path: ROUTES.CATEGORIES,
		element: makeCategories(),
	},
	// SINGLE CATEGORY
	{
		path: `${ROUTES.CATEGORY}/:contentIdWithNiceName`,
		element: makeCategory(),
	},
	{
		path: ROUTES.SEARCH,
		element: (
			<DefaultLayout>
				<SearchPage />
			</DefaultLayout>
		),
	},
	{
		path: ROUTES.NOT_FOUND,
		element: (
			<DefaultLayout>
				<NotFound />
			</DefaultLayout>
		),
	},
]);
