import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
	return (
		<Box display='flex' justifyContent='center' padding={4}>
			<CircularProgress />
		</Box>
	);
};
