import type { OrderByDirection } from '@domain/enum/OrderByDirection';
import type { CollectionSortField } from '@domain/usecases/collections/CollectionSortField';

export const SORT_OPTIONS = [
	{
		value: 'name|asc',
		label: 'screens.categories.sortDropdown.nameAtoZ',
	},
	{
		value: 'name|desc',
		label: 'screens.categories.sortDropdown.nameZtoA',
	},
	{
		value: 'numberOfPuzzles|desc',
		label: 'screens.categories.sortDropdown.mostItems',
	},
	{
		value: 'numberOfPuzzles|asc',
		label: 'screens.categories.sortDropdown.leastItems',
	},
] as const satisfies Array<{ value: `${CollectionSortField}|${OrderByDirection}`; label: string }>;
