import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

export const Wrapper = styled(Container)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: 0,

	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));
