import { useCallback, useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import type { ContentItemWithAuthorModel } from '@domain/models';
import { CollectionsService } from '@domain/usecases/collections/CollectionsService';
import { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';
import { useSort } from '@presentation/hooks/useSort';

export const useCategoryContents = (categoryId: string | undefined, collectionsService: CollectionsService) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const { sort, handleSort } = useSort<PuzzleSortField>(PuzzleSortField.publishedAt);

	// Data
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<ContentItemWithAuthorModel[]>([]);
	const [count, setCount] = useState<number>(0);
	const last = useRef<string | undefined>();
	const [hasMore, setHasMore] = useState(false);

	// Handlers
	const fetchContent = useCallback(
		async (hardReset?: boolean) => {
			if (isLoading || !categoryId) {
				return;
			}

			setIsLoading(true);
			const lastId = !hardReset ? last.current : undefined;

			try {
				const res = await collectionsService.getContentByCollection(categoryId, {
					order: sort,
					last: lastId,
				});

				setData((prevState) => {
					if (hardReset) {
						return [...res.items];
					}

					return [...prevState].concat([...res.items]);
				});
				setCount(res.count);

				if (res.items.length) {
					last.current = res.items[res.items.length - 1].id;
				}
				setHasMore(res.hasMore);
			} catch (e) {
				enqueueSnackbar(t('screens.category.errors.fetchDataError'), { variant: 'error' });
			} finally {
				setIsLoading(false);
			}
		},
		[sort, isLoading, collectionsService, enqueueSnackbar, t, categoryId]
	);

	// Hooks
	useEffect(() => {
		// Fetch on init
		fetchContent(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort, categoryId]);

	return {
		data,
		hasMore,
		handleSort,
		sort,
		fetchContent,
		isLoading,
		count,
	};
};
