import type { ContentItemModel, ContentItemWithAuthorModel } from '@domain/models';
import type { ListOrder } from '@domain/types/ListOrder';
import { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';

export enum DynamicCollections {
	RecentlyAdded = 'recently-added',
	MostPlayed = 'most-played',
	MostLiked = 'most-liked',
}

export interface ListOptions {
	limit: number;
	last?: string;
	order?: ListOrder<PuzzleSortField>;
}

export interface IPuzzlesRepository {
	getPuzzleById(id: string): Promise<ContentItemWithAuthorModel | null>;
	getDailyPuzzle(date: Date): Promise<ContentItemWithAuthorModel | null>;
	countPuzzlesByPublisher(publisherId: string): Promise<number>;
	getPuzzlesByPublisher(publisherId: string, options: ListOptions): Promise<ContentItemModel[]>;
	countPuzzlesByCollection(collectionId: string): Promise<number>;
	getPuzzlesByCollectionId(collectionId: string, options: ListOptions): Promise<ContentItemWithAuthorModel[]>;
	getPuzzlesFromDynamicCollection(collectionName: DynamicCollections): Promise<ContentItemWithAuthorModel[]>;
}
