import { CollectionsService } from '@domain/usecases/collections/CollectionsService';
import { makePuzzleRepository } from '@main/factories/makePuzzleRepository';

import { makeCollectionRepository } from './makeCollectionRepository';

export const makeCollectionService = (): CollectionsService => {
	const collectionsRepository = makeCollectionRepository();
	const puzzleRepository = makePuzzleRepository();

	return new CollectionsService(collectionsRepository, puzzleRepository);
};
