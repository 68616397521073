import { Box, styled, Avatar, type ContainerProps } from '@mui/material';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

export const AuthorHeaderContainer = styled(Container)<ContainerProps & { isSmartBannerOpen: boolean }>(
	({ theme, isSmartBannerOpen }) => ({
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,

		[theme.breakpoints.down('lg')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
		},
		[theme.breakpoints.down('md')]: {
			marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
		},
	})
);

export const AuthorHeaderWrapper = styled(Box)(({ theme }) => ({
	background: theme.colors.darkerBackground,
	borderBottom: `solid 1px ${theme.colors.border}`,
	width: '100%',

	[theme.breakpoints.down('lg')]: {
		padding: `${theme.spacing(4)} 0`,
	},

	[theme.breakpoints.up('lg')]: {
		padding: `${theme.spacing(5)} 0`,
	},
}));

export const CoverImage = styled('img')(() => ({
	width: '100%',
	height: '100%',
	maxWidth: '100%',
	minHeight: '150px',
	minWidth: '100%',
	borderRadius: '8px',
	objectFit: 'cover',
}));

export const ProfileImage = styled(Avatar)(({ theme }) => ({
	width: '144px',
	height: '144px',
	border: `solid 5px ${theme.colors.background}`,
	borderRadius: '100%',
	objectFit: 'cover',
	marginTop: '-120px',
	marginLeft: '24px',

	[theme.breakpoints.down('md')]: {
		marginLeft: 'calc(50% - 144px / 2)',
		marginRight: 'calc(50% - 144px / 2)',
	},
}));

export const CreatorName = styled(Typography)(() => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	'-webkit-line-clamp': '2',
	'-webkit-box-orient': 'vertical',
}));
