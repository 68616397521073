import React, { useCallback } from 'react';
import type { FC } from 'react';
import { isMobile } from 'react-device-detect';
import logo from '@presentation/assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { LINK_SINGULAR } from '@main/config/links';
import { SmartBanner } from '@presentation/components/SmartBanner/SmartBanner';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import { useDownloadApp } from '@presentation/hooks/useDownloadApp';

import { DownloadButtonMobile, HeaderToolbar, Logo, MenuButton, MobileMenuItem, Separator, Wrapper } from './styles';

const pages = [
	{
		key: 'general.menu.categories',
		path: '/categories',
	},
	{
		key: 'general.menu.creators',
		path: '/creators',
	},
	{
		key: 'general.menu.monetize',
		path: 'https://www.gotiva.global',
	},
];

export const Header: FC = () => {
	const { t } = useTranslation();
	const { isOpen } = useSmartBanner();
	const { openDownloadModal } = useDownloadApp();
	const theme = useTheme();

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

	const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	}, []);

	const handleCloseNavMenu = useCallback(() => {
		setAnchorElNav(null);
	}, []);

	const downloadApp = useCallback(() => {
		if (isMobile) {
			window.open(LINK_SINGULAR);
			return;
		}

		openDownloadModal();
	}, [openDownloadModal]);

	return (
		<>
			<Wrapper isSmartBannerOpen={isOpen} position='fixed' color='transparent'>
				<SmartBanner />
				<Container maxWidth='xl'>
					<HeaderToolbar disableGutters>
						<Link to='/'>
							<Logo src={logo} alt={t('general.header.logoAlt')} />
						</Link>

						<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, marginRight: 0 }}>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={anchorElNav ? handleCloseNavMenu : handleOpenNavMenu}
								color='inherit'
							>
								{anchorElNav ? <CloseIcon /> : <MenuIcon />}
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								anchorPosition={{
									top: isOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
									left: 0,
								}}
								anchorReference='anchorPosition'
								marginThreshold={0}
								slotProps={{
									paper: {
										sx: {
											padding: `${theme.padding.big}px ${theme.padding.small}px`,
											width: '100vw',
											maxWidth: '100vw',
											left: '0',
											right: '0',
										},
									},
								}}
								open={!!anchorElNav}
								onClose={handleCloseNavMenu}
								MenuListProps={{ sx: { py: 0 } }}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								<DownloadButtonMobile fullWidth size='medium' variant='outlined' onClick={downloadApp}>
									<Trans i18nKey='general.header.downloadApp' />
								</DownloadButtonMobile>

								{pages.map((page) => (
									<MobileMenuItem key={page.key} component={Link} to={page.path} onClick={handleCloseNavMenu}>
										<Typography textAlign='center'>
											<Trans i18nKey={page.key} />
										</Typography>
									</MobileMenuItem>
								))}
							</Menu>
						</Box>
						{/* [END] Mobile menu*/}

						<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginRight: 0 }}>
							{pages.map((page, index) => (
								<>
									{index !== 0 && <Separator>|</Separator>}
									<MenuButton key={page.key} component={Link} to={page.path} color='secondary' size='medium'>
										<Trans i18nKey={page.key} />
									</MenuButton>
								</>
							))}

							<MenuButton size='medium' variant='outlined' onClick={downloadApp}>
								<Trans i18nKey='general.header.downloadApp' />
							</MenuButton>
						</Box>
					</HeaderToolbar>
				</Container>
			</Wrapper>
		</>
	);
};
