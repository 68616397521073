import { useCallback, useEffect, useRef, useState } from 'react';
import type { CreatorsService } from '@domain/usecases/creators/CreatorsService';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CreatorSortField } from '@domain/usecases/creators/CreatorSortField';
import { useSort } from '@presentation/hooks/useSort';

export const useGetCreators = (creatorsService: CreatorsService) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const { sort, handleSort } = useSort<CreatorSortField>(CreatorSortField.puzzles, 'desc');

	// Data
	const [isLoading, setIsLoading] = useState(false);
	const [creators, setCreators] = useState<any[]>([]);
	const [count, setCount] = useState<number>(0);
	const last = useRef<string | undefined>();
	const [hasMore, setHasMore] = useState(false);

	// Handlers
	const fetchContent = useCallback(
		async (hardReset?: boolean) => {
			if (isLoading) {
				return;
			}

			setIsLoading(true);
			const lastId = !hardReset ? last.current : undefined;

			try {
				const res = await creatorsService.getNextCreatorsPage({
					last: lastId,
					order: sort,
				});

				setCreators((prevState) => {
					if (hardReset) {
						return [...res.items];
					}

					return [...prevState].concat([...res.items]);
				});
				setCount(res.count);

				if (res.items.length) {
					last.current = res.items[res.items.length - 1].id;
				}
				setHasMore(res.hasMore);
			} catch (e) {
				enqueueSnackbar(t('screens.creators.errors.fetchDataError'), { variant: 'error' });
			} finally {
				setIsLoading(false);
			}
		},
		[sort, isLoading, enqueueSnackbar, t, creatorsService]
	);

	// Hooks
	useEffect(() => {
		// Fetch on init
		fetchContent(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	return {
		creators,
		hasMore,
		handleSort,
		sort,
		fetchContent,
		isLoading,
		count,
	};
};
