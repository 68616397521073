import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { EnvironmentType } from '@config/firebase';
import { Trans } from 'react-i18next';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

const NotFound = () => {
	const { generateLink } = useSmartBanner();

	const error = useRouteError();

	useEffect(() => {
		generateLink('HOME');
	}, [generateLink]);

	if (import.meta.env.VITE_ENVIRONMENT !== EnvironmentType.PRODUCTION) {
		console.error(error);
	}

	return <Trans i18nKey='screens.notFound.title' />;
};

export default NotFound;
