import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContentDetailsModal } from '@presentation/context/ContentDetailsModalContextProvider';

export const useShowContentDetailsModalBasedOnUrl = () => {
	const params = useParams();
	const { showContentDetails } = useContentDetailsModal();

	const getContentIdFromParams = useCallback((): string | undefined => {
		// Handle /puzzle/:contentIdWithNiceName.
		if (typeof params.contentIdWithNiceName === 'string') {
			return params.contentIdWithNiceName.split('-')[0];
		}

		// Handle /puzzle/:contentId/:niceName.
		if (typeof params.contentId === 'string') {
			return params.contentId;
		}

		return undefined;
	}, [params]);

	useEffect(() => {
		(async () => {
			const contentId = getContentIdFromParams();

			if (!contentId) {
				return;
			}

			showContentDetails(contentId, 'puzzle');
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps -- We need params only for initial load when there is a fresh page openModal.
	}, []);
};
