import React from 'react';
import { type AppBarProps, type ButtonProps, type MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

export const Wrapper = styled(AppBar)<AppBarProps & { isSmartBannerOpen: boolean }>(({ theme, isSmartBannerOpen }) => ({
	display: 'flex',
	justifyContent: 'center',
	height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,
	backgroundColor: theme.colors.darkerBackground,
	zIndex: theme.zIndex.drawer,
	boxShadow: '0px 2px 4px 0px #00000026',

	[theme.breakpoints.down('lg')]: {
		height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
	},
	[theme.breakpoints.down('md')]: {
		height: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
	},
}));

export const HeaderToolbar = styled(Toolbar)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const Separator = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: theme.colors.menuLink,
}));

export const MenuButton = styled(Button)<ButtonProps & { component?: React.ElementType; to?: string }>(({ theme }) => ({
	margin: `0 ${theme.spacing(1)}`,
	textTransform: 'none',
}));

export const DownloadButtonMobile = styled(MenuButton)<ButtonProps & { component?: React.ElementType; to?: string }>(
	() => ({
		marginLeft: 0,
	})
);

export const MobileMenuItem = styled(MenuItem)<MenuItemProps & { component?: React.ElementType; to?: string }>(() => ({
	margin: 0,
	padding: '6px 0',
}));

export const Logo = styled('img')(({ theme }) => ({
	marginTop: theme.spacing(1),
}));
