import React, { useEffect } from 'react';
import { ContentContainer, ListContent } from '@presentation/layouts/styles';
import { ListHeader } from '@presentation/components/ListHeader';
import type { CreatorsService } from '@domain/usecases/creators/CreatorsService';
import { Loader } from '@presentation/components/Loader';
import { useCreatorContents } from '@presentation/pages/Creator/hooks/useCreatorContents';
import type { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';
import { ArtworkInfiniteScrollList } from '@presentation/components/ArtworkInfiniteScrollList';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

import NotFound from '../NotFound';

import { AuthorHeader } from './components/AuthorHeader';
import { useGetCreatorIfApproved } from './hooks/useGetCreatorIfApproved';
import { SORT_OPTIONS } from './constants';

const CreatorPage = ({ creatorsService }: { creatorsService: CreatorsService }) => {
	const { isOpen, generateLink } = useSmartBanner();
	const { id, creator, isLoading } = useGetCreatorIfApproved(creatorsService);
	const { data, fetchContent, hasMore, handleSort, sort } = useCreatorContents(creator?.id, creatorsService);

	useEffect(() => {
		if (id) {
			generateLink('ARTIST', id);
		}
	}, [id, generateLink]);

	if (isLoading) {
		return (
			<ContentContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<Loader />
			</ContentContainer>
		);
	}

	/* TODO Move this check on backend side */
	if (!creator) {
		return (
			<ContentContainer isSmartBannerOpen={isOpen} maxWidth='xl'>
				<NotFound />
			</ContentContainer>
		);
	}

	return (
		<>
			<AuthorHeader authorData={creator} />
			<ListContent isSmartBannerOpen={isOpen} maxWidth='xl'>
				<ListHeader
					sortOptions={SORT_OPTIONS}
					sortBy={`${sort.field}|${sort.direction}`}
					onSortChange={(orderBy, orderDirection) => handleSort(orderBy as PuzzleSortField, orderDirection)}
				/>
				<ArtworkInfiniteScrollList
					data={data.map((item) => ({
						...item,
						author: creator,
					}))}
					hasMore={hasMore}
					loadMore={() => fetchContent()}
				/>
			</ListContent>
		</>
	);
};

export default CreatorPage;
