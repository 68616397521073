import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';

export const Wrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	height: theme.sizes.smartBanner.height,
	padding: '0 5px',
	borderBottom: `1px solid ${theme.colors.border}`,
	marginBottom: '5px',
}));

export const Icon = styled('img')(() => ({
	borderRadius: '5px',
	margin: '0 12px',
}));

export const Button = styled(MUIButton)(() => ({
	margin: '0 10px',
}));
