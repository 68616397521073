import type { OrderByDirection } from '@domain/enum/OrderByDirection';
import { CreatorSortField } from '@domain/usecases/creators/CreatorSortField';

export const SORT_OPTIONS = [
	{
		value: 'normalizedUsername|asc',
		label: 'screens.creators.sortDropdown.nameAtoZ',
	},
	{
		value: 'normalizedUsername|desc',
		label: 'screens.creators.sortDropdown.nameZtoA',
	},
	{
		value: 'createdAt|asc',
		label: 'screens.creators.sortDropdown.joiningDateOldest',
	},
	{
		value: 'createdAt|desc',
		label: 'screens.creators.sortDropdown.joiningDateOldestNewest',
	},
	{
		value: 'Puzzles|asc',
		label: 'screens.creators.sortDropdown.contentItemsLeast',
	},
	{
		value: 'Puzzles|desc',
		label: 'screens.creators.sortDropdown.contentItemsMost',
	},
	{
		value: 'Followers|asc',
		label: 'screens.creators.sortDropdown.followersLeast',
	},
	{
		value: 'Followers|desc',
		label: 'screens.creators.sortDropdown.followersMost',
	},
] as const satisfies Array<{ value: `${CreatorSortField}|${OrderByDirection}`; label: string }>;
