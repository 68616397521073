export enum EnvironmentType {
	DEVELOPMENT = 'development',
	STAGING = 'staging',
	PRODUCTION = 'production',
	EMULATOR = 'emulator',
}

export interface ConfigType {
	FIREBASE_URL?: string; // here you need to add all of the configuration for the firebase
	ENVIRONMENT: EnvironmentType;
	API_KEY: string;
	AUTH_DOMAIN: string;
	PROJECT_ID: string;
	STORAGE_BUCKET: string;
	MESSAGING_SENDER_ID: string;
	APP_ID: string;
	RECAPTCHA_KEY: string;
	FIREBASE_APPCHECK_DEBUG_TOKEN?: string;
}

const firebase: ConfigType = {
	ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
	API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
	AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
	RECAPTCHA_KEY: import.meta.env.VITE_FIREBASE_RECAPTCHA_KEY,
};

export default firebase;
