import React, { type FC } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import icon from '@presentation/assets/icon.webp';
import Typography from '@mui/material/Typography';
import { MobileView, isIOS } from 'react-device-detect';
import { Icon, Wrapper, Button } from '@presentation/components/SmartBanner/styles';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';
import { useTranslation } from 'react-i18next';

export const SmartBanner: FC = () => {
	const { t } = useTranslation();
	const { isOpen, link, closeBanner } = useSmartBanner();

	if (!isOpen) {
		return;
	}

	return (
		<MobileView>
			<Wrapper>
				<Box display='flex' alignItems='center'>
					<IconButton onClick={closeBanner} size='small'>
						<CloseIcon fontSize='small' />
					</IconButton>
					<Icon src={icon} width={62} height={62} alt={t('general.name')} />
					<Box>
						<Typography variant='smartBannerTitle'>{t('general.name')}</Typography>
						<Typography display='block' variant='smartBannerSubtitle'>
							{isIOS ? t('components.smartBanners.iOSFree') : t('components.smartBanners.androidFree')}
						</Typography>
					</Box>
				</Box>
				<Box>
					<Button size='small' variant='outlined' onClick={() => window.open(link, '_blank')}>
						{t('components.smartBanners.button')}
					</Button>
				</Box>
			</Wrapper>
		</MobileView>
	);
};
