export const ROUTES = {
	HOME: '/',
	CREATORS: '/creators',
	CREATOR: '/creator',
	CATEGORIES: '/categories',
	PUZZLE: '/puzzle',
	SEARCH: '/search',
	CATEGORY: '/category',
	DOWNLOAD: '/download',
	NOT_FOUND: '/404',
};
