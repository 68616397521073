import React from 'react';
import ListLayout from '@presentation/layouts/ListLayout';
import Categories from '@presentation/pages/Categories';

import { makeCollectionService } from '../makeCollectionService';

const makeCategories = () => {
	const collectionsService = makeCollectionService();

	return (
		<ListLayout>
			<Categories collectionsService={collectionsService} />
		</ListLayout>
	);
};

export default makeCategories;
