import theme from '@presentation/styling/theme';

export const InfoBoxProps = {
	item: true,
	flex: 1,
	border: `solid 1px ${theme.colors.border}`,
	borderRadius: '8px',
	padding: '8px 10px',
	bgcolor: theme.colors.background,
};
