import { type GridProps, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import MUIContainer from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import type { TypographyProps } from '@mui/material/Typography/Typography';

export const Wrapper = styled(MUIContainer)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: 0,

	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const Container = styled(Grid)<GridProps & { bgimage?: string }>(({ theme, bgimage }) => ({
	position: 'relative',
	border: `1px solid ${theme.colors.border}`,
	borderRadius: '16px',
	backgroundColor: theme.colors.darkerBackground,
	backgroundImage: bgimage ? `url("${bgimage}")` : undefined,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	margin: `${theme.spacing(1)} auto`,
	width: '100%',
	padding: 0,
	overflow: 'hidden',
	minHeight: '50vw',

	[theme.breakpoints.up('sm')]: {
		minHeight: '33vw',
	},

	[theme.breakpoints.up('md')]: {
		minHeight: '155px',
		width: '100%',
		padding: theme.spacing(4),
		margin: `${theme.spacing(1)} 0`,
	},

	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(4),
		margin: `${theme.spacing(1)} 0`,
	},
}));

export const LeftColumn = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(3),
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const RightColumn = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(3),
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const BackgroundImage = styled('img')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	objectPosition: 'center',
	opacity: 0.7,
	zIndex: theme.zIndex.fab,
}));

export const BannerTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
	fontSize: '25px',

	[theme.breakpoints.up('md')]: {
		fontSize: '32px',
	},
}));

export const BannerImageContainer = styled('div')(() => ({
	width: '100%',
}));

export const BannerImage = styled('img')(({ theme }) => ({
	width: '100%',
	margin: '0 auto',
	display: 'block',
	maxWidth: `${theme.sizes.bannerImage.maxWidth}px`,
	aspectRatio: '1/1',
	objectFit: 'fill',
	marginLeft: 'auto',
	borderRadius: '16px',
	zIndex: theme.zIndex.appBar,

	[theme.breakpoints.up('md')]: {
		float: 'right',
	},
}));

export const Overlay = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	zIndex: theme.zIndex.appBar,
	background:
		'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 45.63%)',
}));
