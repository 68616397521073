import type { ICollectionsRepository } from '@domain/usecases/collections/CollectionsRepository';
import type { CollectionOrderBy } from '@domain/usecases/collections/CollectionOrderBy';
import type { CollectionModel } from '@domain/models/CollectionModel';
import type { IPuzzlesRepository } from '@domain/usecases/puzzles/PuzzlesRepository';
import type { ContentItemWithAuthorModel } from '@domain/models';
import type { ListOrder } from '@domain/types/ListOrder';
import type { PuzzleSortField } from '@domain/usecases/puzzles/PuzzleSortField';

export class CollectionsService {
	private DEFAULT_ITEMS_LIMIT = 12;

	constructor(
		private readonly collectionsRepository: ICollectionsRepository,
		private readonly puzzlesRepository: IPuzzlesRepository
	) {}

	async getCollectionById(id: string): Promise<CollectionModel | null> {
		return this.collectionsRepository.getById(id);
	}

	async getContentByCollection(
		collectionId: string,
		options: { last?: string; order?: ListOrder<PuzzleSortField> }
	): Promise<{ items: ContentItemWithAuthorModel[]; count: number; hasMore: boolean }> {
		const [items, count] = await Promise.all([
			this.puzzlesRepository.getPuzzlesByCollectionId(collectionId, { limit: this.DEFAULT_ITEMS_LIMIT, ...options }),
			this.puzzlesRepository.countPuzzlesByCollection(collectionId),
		]);

		return {
			items,
			count,
			hasMore: items.length == this.DEFAULT_ITEMS_LIMIT,
		};
	}

	async getNextCollectionsPage(options: {
		last?: any;
		order?: CollectionOrderBy;
	}): Promise<{ items: CollectionModel[]; count: number; hasMore: boolean }> {
		const collections = await this.collectionsRepository.getNotEmptyCollections({
			limit: this.DEFAULT_ITEMS_LIMIT,
			...options,
		});
		const count = await this.collectionsRepository.countActive();

		return {
			count: count,
			items: collections,
			hasMore: collections.length === this.DEFAULT_ITEMS_LIMIT,
		};
	}
}
