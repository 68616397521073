import React, { useCallback, useEffect } from 'react';
import { ContentDetailsModal } from '@presentation/components/ContentDetailsModal';
import type { ContentService } from '@domain/usecases/content/ContentService';
import Modal from '@mui/material/Modal';
import { useGetContentDetails } from '@presentation/modals/ContentDetails/hooks';
import { Loader } from '@presentation/components/Loader';

const ContentDetails = ({
	id,
	open,
	onClose,
	contentService,
}: {
	id: string;
	open: boolean;
	onClose: () => void;
	contentService: ContentService;
}) => {
	const { content, appLink, isLoading, reset } = useGetContentDetails(id, contentService);

	const close = useCallback(() => {
		reset();
		onClose();
	}, [onClose, reset]);

	const onKeyDown = useCallback(
		(event: any) => {
			if (event.key !== 'Escape') {
				return;
			}

			event.stopPropagation();

			close();
		},
		[close]
	);

	useEffect(() => {
		if (!isLoading && content === null) {
			onClose();
		}
	}, [isLoading, content, onClose]);

	return (
		<Modal open={open} onClose={close}>
			<div onKeyDown={onKeyDown}>
				{isLoading && <Loader />}
				{content && !isLoading && <ContentDetailsModal onClose={close} content={content} linkInApp={appLink} />}
			</div>
		</Modal>
	);
};

export default ContentDetails;
