import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const Wrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.colors.background,
	marginBottom: theme.spacing(2),
}));
export const HeaderWrapper = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.colors.darkerBackground,
	padding: theme.spacing(2),
	borderRadius: '8px',
	cursor: 'pointer',
}));

export const ButtonContainer = styled(Grid)(({ theme }) => ({
	justifyContent: 'flex-start',

	[theme.breakpoints.up('md')]: {
		justifyContent: 'flex-end',
	},
}));
