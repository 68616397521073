import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSmartBanner } from '@presentation/context/SmartBannerContextProvider';

const SearchPage = () => {
	const { generateLink } = useSmartBanner();

	useEffect(() => {
		generateLink('HOME');
	}, [generateLink]);

	return <Trans i18nKey='screens.search.title' />;
};

export default SearchPage;
