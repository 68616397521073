import { collection, limit, orderBy, query, startAfter, where, type DocumentSnapshot } from 'firebase/firestore';
import { firestore } from '@infrastructure/firebase';

import type { FilterItem } from './type/FilterItem';
import type { OrderItem } from './type/OrderItem';

export const getQuery = <Schema>(
	collectionName: string,
	filters?: FilterItem<Schema>[],
	ordering?: OrderItem<Schema>[],
	last?: DocumentSnapshot | undefined,
	limitAmount?: number | undefined
) => {
	const collectionRef = collection(firestore, collectionName);
	const queryRef = query(
		collectionRef,
		...(filters ?? []).map((filter) => where(filter?.attribute, filter?.operator, filter?.value)),
		...(ordering ?? []).map((order) => orderBy(order.field, order.type)),
		...(last ? [startAfter(last)] : []),
		...(limitAmount ? [limit(limitAmount)] : [])
	);

	return queryRef;
};
