import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React from 'react';
import { Trans } from 'react-i18next';
import { AuthorInfo } from '@presentation/components/AuthorInfo';
import { useNavigate } from 'react-router-dom';
import type { ContentItemModel, CreatorModel } from '@domain/models';
import { ArtworkCard } from '@presentation/components/ArtworkCard';
import { useMediaQuery } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';
import { useSingularLinkRedirect } from '@presentation/hooks/useSingularLinkRedirect';

import { ButtonContainer, HeaderWrapper, Wrapper } from './styles';

export type ContentSectionProps = {
	creator: CreatorModel;
	content: ContentItemModel;
	children: React.ReactElement;
};
export const CreatorSection = (props: ContentSectionProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const navigate = useNavigate();

	const { creator, content, children } = props;

	const { redirectToApp } = useSingularLinkRedirect(LinkGeneratorService.LINK_TYPES.ARTIST, creator.id);

	return (
		<Wrapper>
			<HeaderWrapper
				onClick={() => navigate(`/${creator.profileLink}`)}
				container
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				marginBottom={3}
			>
				<Grid item xs={12} md={6}>
					<AuthorInfo creator={creator} color={theme.colors.text} />
				</Grid>
				<Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' } }}>
					<ArtworkCard
						id={content.id}
						creator={creator}
						imageName={content.name}
						imageSrc={content.imageSrc}
						onAuthorClick={(event) => {
							event.stopPropagation();
							navigate(`/${creator.profileLink}`);
						}}
					/>
				</Grid>
				<ButtonContainer
					item
					xs={12}
					md={6}
					display='flex'
					alignItems='center'
					sx={{
						marginTop: { xs: 1, md: 0 },
						justifyContent: { xs: 'center', md: 'flex-end' },
					}}
				>
					<Button
						variant='contained'
						style={{
							textTransform: 'none',
						}}
						fullWidth={isMobile}
						size='large'
						color='buttonBlue'
						onClick={redirectToApp}
					>
						<Trans i18nKey='components.creatorSection.viewInApp' />
					</Button>
				</ButtonContainer>
			</HeaderWrapper>
			<Box display='flex'>{children}</Box>
		</Wrapper>
	);
};
