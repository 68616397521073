import React from 'react';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import theme from '@presentation/styling/theme';
import { RemoteConfigProvider } from '@presentation/context/RemoteConfigContextProvider';
import { SmartBannerContextProvider } from '@presentation/context/SmartBannerContextProvider';
import { SnackbarProvider } from 'notistack';

import router from './routes';
import './config/i18n';
import 'react-alice-carousel/lib/alice-carousel.css';

const App = () => {
	return (
		<RemoteConfigProvider>
			<ThemeProvider theme={theme}>
				<SmartBannerContextProvider>
					<SnackbarProvider maxSnack={3}>
						<CssBaseline />
						<RouterProvider router={router} />
					</SnackbarProvider>
				</SmartBannerContextProvider>
			</ThemeProvider>
		</RemoteConfigProvider>
	);
};

export default App;
