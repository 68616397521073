import { Box, type BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)<BoxProps & { isSmartBannerOpen: boolean }>(({ theme, isSmartBannerOpen }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.xxl : theme.sizes.header.xxl,
	backgroundColor: theme.colors.darkerBackground,

	[theme.breakpoints.down('lg')]: {
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.lg : theme.sizes.header.lg,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},

	[theme.breakpoints.up('lg')]: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},

	[theme.breakpoints.down('md')]: {
		marginTop: isSmartBannerOpen ? theme.sizes.headerWidthSmartBanner.md : theme.sizes.header.md,
	},
}));
