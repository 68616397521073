import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

import firebase, { EnvironmentType } from '../../config/firebase';

const firebaseConfig = {
	apiKey: firebase.API_KEY,
	authDomain: firebase.AUTH_DOMAIN,
	projectId: firebase.PROJECT_ID,
	storageBucket: firebase.STORAGE_BUCKET,
	messagingSenderId: firebase.MESSAGING_SENDER_ID,
	appId: firebase.APP_ID,
};

export const app = initializeApp(firebaseConfig);

// NOTE: debug token will be automatically added if it is the local environment
// same debug token is used for all developers (no need for refreshing tokens
// because the debug token will be only used on the local and dev environment)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
self.FIREBASE_APPCHECK_DEBUG_TOKEN = firebase.FIREBASE_APPCHECK_DEBUG_TOKEN;

export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(firebase.RECAPTCHA_KEY),
	isTokenAutoRefreshEnabled: true,
});

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const remoteConfig = getRemoteConfig(app);

if (firebase.ENVIRONMENT === EnvironmentType.DEVELOPMENT) {
	remoteConfig.settings.minimumFetchIntervalMillis = 0;
} else if (firebase.ENVIRONMENT === EnvironmentType.PRODUCTION) {
	remoteConfig.settings.minimumFetchIntervalMillis = 30 * 60 * 1000; // 30 minutes.
}

if (firebase.ENVIRONMENT === EnvironmentType.EMULATOR) {
	connectAuthEmulator(auth, 'http://localhost:9099');
	connectFunctionsEmulator(functions, 'localhost', 5001);
	connectFirestoreEmulator(firestore, 'localhost', 8080);
}
