import { type MouseEventHandler, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { useContentDetailsModal } from '@presentation/context/ContentDetailsModalContextProvider';
import type { CreatorModel } from '@domain/models';

import { AuthorInfo } from '../AuthorInfo';

import { Overlay, Wrapper } from './styles';

export type ArtworkCardProps = {
	id: string;
	imageSrc: string;
	imageName: string;
	creator: CreatorModel | null;
	onClick?: () => void;
	onAuthorClick?: MouseEventHandler;
};

export const ArtworkCard = (props: ArtworkCardProps) => {
	const theme = useTheme();
	const { showContentDetails } = useContentDetailsModal();

	const { id, imageSrc, imageName, creator, onClick, onAuthorClick } = props;

	const onWrapperClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
		onClick && onClick();

		showContentDetails(id, 'puzzle');
	}, [id, onClick, showContentDetails]);

	return (
		<Wrapper
			onClick={onWrapperClick}
			border={1}
			borderRadius='16px'
			borderColor={theme.colors.border}
			bgimage={imageSrc}
		>
			<Overlay />
			<AuthorInfo
				creator={creator}
				contentTitle={imageName}
				color={theme.colors.cardTextColor}
				onClick={onAuthorClick}
			/>
		</Wrapper>
	);
};
