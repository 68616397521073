import { useCallback } from 'react';
import { ROUTES } from '@main/routes/routes';
import DownloadApp from '@presentation/modals/DownloadApp';
import { useModal } from '@presentation/context/ModalContextProvider';

export function useDownloadApp(): { openDownloadModal: () => void } {
	const { openModal, closeModal } = useModal();

	const openDownloadModal = useCallback(() => {
		const currentPath = window.location.pathname;
		const prev = currentPath.includes(ROUTES.DOWNLOAD) ? '/' : currentPath;

		history.pushState({}, '', ROUTES.DOWNLOAD);

		openModal('download-app', DownloadApp, {
			open: true,
			onClose: () => {
				closeModal('download-app');
				history.pushState({}, '', prev);
			},
		});
	}, [openModal, closeModal]);

	return {
		openDownloadModal,
	};
}
