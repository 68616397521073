import { useCallback, useEffect, useState } from 'react';
import type { ContentService } from '@domain/usecases/content/ContentService';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';
import type { ContentItemWithCollectionsModel } from '@domain/models/ContentItemWithCollectionsModel';

export const useGetContentDetails = (id: string, contentService: ContentService) => {
	const [content, setContentDetails] = useState<ContentItemWithCollectionsModel | null>();
	const [appLink, setContentAppLink] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(true);

	const reset = useCallback(() => {
		setContentDetails(null);
	}, [setContentDetails]);

	const fetchContentDetails = useCallback(async () => {
		setLoading(true);
		setContentDetails(null);

		if (!id) {
			setLoading(false);
			return;
		}

		const document = await contentService.getContentById('puzzle', id);
		setContentDetails(document);

		setContentAppLink(LinkGeneratorService.getSingularLink(LinkGeneratorService.LINK_TYPES.PUZZLE, id));

		setLoading(false);
	}, [id, contentService]);

	useEffect(() => {
		fetchContentDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return { content, appLink, isLoading, reset };
};
