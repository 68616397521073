import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import { Wrapper } from './styles';

export const RowLoader = ({ mobileThin }: { mobileThin?: boolean }) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const height = useMemo(() => (isMobile && mobileThin ? 100 : 234), [isMobile, mobileThin]);
	const xsColumn = useMemo(() => (isMobile && mobileThin ? 12 : 9), [isMobile, mobileThin]);

	return (
		<Wrapper maxWidth='xl'>
			<Grid container spacing={3} alignContent='center' justifyContent='center' paddingTop={3}>
				<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
					<Skeleton variant='rounded' height={height} />
				</Grid>
				{!isMobile && (
					<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
				)}
				{!isMobile && (
					<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
				)}
				{!isMobile && (
					<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
				)}
				{!isTablet && (
					<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
				)}
				{!isTablet && (
					<Grid item xs={xsColumn} sm={4} md={3} lg={2}>
						<Skeleton variant='rounded' height={height} />
					</Grid>
				)}
			</Grid>
		</Wrapper>
	);
};
