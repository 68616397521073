import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { CreatorsService } from '@domain/usecases/creators/CreatorsService';
import type { CreatorModel } from '@domain/models';

export const useGetCreatorIfApproved = (creatorsService: CreatorsService) => {
	const { idOrProfileLink } = useParams();
	const parsedIdentifier = useMemo(() => idOrProfileLink?.split('-')[0] || '', [idOrProfileLink]);

	const [creator, setCreator] = useState<CreatorModel | null>();
	const [isLoading, setLoading] = useState<boolean>(false);

	const fetchCreator = useCallback(async () => {
		setLoading(true);

		if (!parsedIdentifier) {
			setLoading(false);
			return;
		}

		const document = await creatorsService.getCreatorIfApproved({ idOrProfileLink: parsedIdentifier });

		setCreator(document);

		setLoading(false);
	}, [parsedIdentifier, creatorsService]);

	useEffect(() => {
		fetchCreator();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedIdentifier]);

	return { id: parsedIdentifier, creator, isLoading };
};
