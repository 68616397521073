import { remoteConfig } from '@infrastructure/firebase';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import type { HomepageCategoryItem, IConfigRepository } from '@domain/usecases/config/ConfigRepository';

export class RemoteConfigRepository implements IConfigRepository {
	static async init() {
		return await fetchAndActivate(remoteConfig);
	}

	getListOfCategoriesForHomepage(): HomepageCategoryItem[] {
		const value = getValue(remoteConfig, 'home_screen_categories').asString();

		if (value) {
			return JSON.parse(value);
		}

		return [];
	}
}
