import React from 'react';
import Creators from '@presentation/pages/Creators';
import ListLayout from '@presentation/layouts/ListLayout';

import { makeCreatorsService } from '../makeCreatorService';

const makeCreators = () => {
	const creatorsService = makeCreatorsService();

	return (
		<ListLayout>
			<Creators creatorsService={creatorsService} />
		</ListLayout>
	);
};

export default makeCreators;
