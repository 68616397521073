import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

export const Wrapper = styled(Container)(({ theme }) => ({
	position: 'relative',
	padding: 0,

	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
	[theme.breakpoints.up('md')]: {
		padding: 0,
	},
}));

export const ItemContainer = styled('div')(({ theme }) => ({
	marginRight: theme.spacing(2),
}));

export const NavigationButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: '45%',
	backgroundColor: theme.colors.darkerBackground,
	zIndex: theme.zIndex.appBar,
	boxShadow: theme.shadow.iconButton,

	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}));

export const NavigationButtonLeft = styled(NavigationButton)(() => ({
	left: 0,
}));

export const NavigationButtonRight = styled(NavigationButton)(() => ({
	right: 0,
}));
