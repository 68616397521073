import { Avatar, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type MouseEventHandler, useMemo } from 'react';
import type { CreatorModel } from '@domain/models';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './styles';

type AuthorInfoProps = {
	creator: CreatorModel | null;
	noShowSubtitle?: boolean;
	contentTitle?: string;
	color?: string;
	onClick?: MouseEventHandler;
};

export const AuthorInfo = (props: AuthorInfoProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { creator, noShowSubtitle, contentTitle, onClick, color } = props;

	const creatorSubtitle = useMemo(() => {
		if (noShowSubtitle) {
			return '';
		}

		if (contentTitle) {
			return creator?.name;
		}

		const parts = [];

		if (creator?.country) {
			parts.push(creator?.country);
		}

		if (creator?.puzzlesCount && creator?.puzzlesCount > 0) {
			parts.push(`${creator?.puzzlesCount} ${t('common.items')}`);
		}

		if (creator?.followersCount && creator?.followersCount > 0) {
			parts.push(`${creator?.followersCount} ${t('common.followers')}`);
		}

		if (creator?.createdAt) {
			parts.push(`${t('common.activeSince')} ${format(creator.createdAt, 'dd.MM.yyyy')}`);
		}

		return parts.join(' | ');
	}, [creator, t, noShowSubtitle, contentTitle]);

	return (
		<Box display='flex' alignItems='center' zIndex={theme.zIndex.appBar} onClick={onClick} sx={{ cursor: 'pointer' }}>
			<Avatar
				src={creator?.avatarSrc}
				alt={creator?.name}
				sx={{ width: theme.sizes.avatar.small, height: theme.sizes.avatar.small }}
			/>
			<Wrapper>
				<Typography variant='h5' color={color} noWrap sx={{ cursor: 'pointer' }}>
					{contentTitle ? contentTitle : creator?.name}
				</Typography>
				<Typography variant='subtitle2' color={color} noWrap sx={{ cursor: 'pointer', whiteSpace: 'break-spaces' }}>
					{creatorSubtitle}
				</Typography>
			</Wrapper>
		</Box>
	);
};
