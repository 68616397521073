import React from 'react';
import ListLayout from '@presentation/layouts/ListLayout';
import Creator from '@presentation/pages/Creator';

import { makeCreatorsService } from '../makeCreatorService';

const makeCreator = () => {
	const creatorsService = makeCreatorsService();

	return (
		<ListLayout>
			<Creator creatorsService={creatorsService} />
		</ListLayout>
	);
};

export default makeCreator;
