import React from 'react';
import Home from '@presentation/pages/Home';
import DefaultLayout from '@presentation/layouts/DefaultLayout';

import { makeHomepageService } from '../makeHomepageService';

const makeHomepage = () => {
	const homepageService = makeHomepageService();

	return (
		<DefaultLayout>
			<Home homepageService={homepageService} />
		</DefaultLayout>
	);
};

export default makeHomepage;
