import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';

type SmartBannerModalContext = {
	isOpen: boolean;
	link: string;
	generateLink: (type: keyof typeof LinkGeneratorService.LINK_TYPES, id?: string) => void;
	setSmartLink: (link: string) => void;
	closeBanner: () => void;
};

const initialValue: SmartBannerModalContext = {
	isOpen: false,
	link: '',
	generateLink: () => {},
	setSmartLink: () => {},
	closeBanner: () => {},
};

const SMART_BANNER_LOCALSTORAGE_KEY = 'smart-banner-closed';
const SMART_BANNER_LINK_LOCALSTORAGE_KEY = 'smart-banner-link';
export const SmartBannerContext = createContext(initialValue);

export const SmartBannerContextProvider = ({ children }: { children: ReactNode }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [link, setLink] = useState<string>(LinkGeneratorService.getSingularLink('HOME'));

	const closeBanner = useCallback<SmartBannerModalContext['closeBanner']>(async () => {
		localStorage.setItem(SMART_BANNER_LOCALSTORAGE_KEY, '1');
		setIsOpen(false);
	}, [setIsOpen]);

	const setSmartLink = useCallback<SmartBannerModalContext['setSmartLink']>(
		async (link: string) => {
			localStorage.setItem(SMART_BANNER_LINK_LOCALSTORAGE_KEY, link);

			setLink(link);
		},
		[setLink]
	);

	const generateLink = useCallback<SmartBannerModalContext['generateLink']>(
		async (type: keyof typeof LinkGeneratorService.LINK_TYPES, id?: string) => {
			const generatedLink = LinkGeneratorService.getSingularLink(type, id);

			setSmartLink(generatedLink);
		},
		[setSmartLink]
	);

	useEffect(() => {
		const isCloseFlag = localStorage.getItem(SMART_BANNER_LOCALSTORAGE_KEY);
		const link = localStorage.getItem(SMART_BANNER_LINK_LOCALSTORAGE_KEY);

		isCloseFlag === '1' ? setIsOpen(false) : setIsOpen(isMobile && !isSafari);

		if (link) {
			setLink(link);
		}
	}, []);

	return (
		<SmartBannerContext.Provider value={{ closeBanner, isOpen, link, generateLink, setSmartLink }}>
			{children}
		</SmartBannerContext.Provider>
	);
};

export const useSmartBanner = () => {
	const context = useContext(SmartBannerContext);

	if (!context) {
		throw new Error('Cannot use ContentDetailsModalContext outside provider');
	}

	return context;
};
