import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://35b7d2347d8da8a7a6576745c5a304c7@o4506733056688128.ingest.us.sentry.io/4507485611950080',
	enabled: !!Number(import.meta.env.VITE_DEBUG ?? '0'), // Disable sentry on local env
	environment: import.meta.env.VITE_ENVIRONMENT,
	release: 'content-wep-portal@' + import.meta.env.VITE_VERSION,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^\//],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
