import { CreatorsService } from '@domain/usecases/creators/CreatorsService';

import { makeCreatorRepository } from './makeCreatorRepository';
import { makePuzzleRepository } from './makePuzzleRepository';

export const makeCreatorsService = (): CreatorsService => {
	const creatorRepository = makeCreatorRepository();
	const puzzleRepository = makePuzzleRepository();

	return new CreatorsService(creatorRepository, puzzleRepository);
};
