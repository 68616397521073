import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { differenceInDays } from 'date-fns';

TimeAgo.addDefaultLocale(en);

export const timeSince = (date: Date) => {
	const diff = differenceInDays(new Date(), date);

	if (diff === 0) {
		return 'Today';
	}

	const timeAgo = new TimeAgo('en-US');
	return `${timeAgo.format(date, 'mini')} ago`;
};
