import React from 'react';
import ListLayout from '@presentation/layouts/ListLayout';
import Category from '@presentation/pages/Category';
import { makeCollectionService } from '@main/factories/makeCollectionService';

const makeCategory = () => {
	const collectionsService = makeCollectionService();

	return (
		<ListLayout>
			<Category collectionsService={collectionsService} />
		</ListLayout>
	);
};

export default makeCategory;
