import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';

export enum LocalesTypes {
	en = 'en',
}

/**
 * Translation strings gathered in single resource.
 */
export const resources = {
	[LocalesTypes.en]: {
		translation: en,
	},
} as const;

i18n.use(initReactI18next).init({
	lng: navigator.language || 'en',
	fallbackLng: 'en',
	resources: resources,
	react: {
		transSupportBasicHtmlNodes: true,
		transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
	},
});
