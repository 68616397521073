import { getDocs, type DocumentSnapshot } from 'firebase/firestore';

import type { FilterItem } from './type/FilterItem';
import type { OrderItem } from './type/OrderItem';
import { getQuery } from './getQuery';

export const getDocuments = async <Schema>(
	collectionName: string,
	filters?: FilterItem<Schema>[],
	ordering?: OrderItem<Schema>[],
	last?: DocumentSnapshot,
	limitAmount?: number | undefined
) => {
	const queryRef = getQuery<Schema>(collectionName, filters, ordering, last, limitAmount);
	return await getDocs(queryRef);
};
